export const environment = {
    type: 'staging',
    apiUrl: 'orchestrator-service.dev.exafysolutions.ae',
    orchestratorUrl: 'orchestrator-service.dev.exafysolutions.ae',
    wssScheme: 'wss',
    httpScheme: 'https',
    design: 'design-1'

    //design: 'design-4'
};
